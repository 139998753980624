import { CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import avail1 from '../images/avail1.png'
import avail2 from '../images/avail2.png'
import avail3 from '../images/avail3.png'
import avail4 from '../images/avail4.png'


function Avail() {
    return (
        <div id='Avail' style={{ marginTop: '3%', padding: '3%' }}>
            <Typography variant='h2' sx={{ fontWeight: 'bold', fontFamily: 'Poppins', margin: '0', padding: '0', fontSize: { md: '4rem', sm: '2rem', xs: '2rem' } }}><strong style={{ color: '#BC09C7' }}>NFT Hodlr </strong> can avail the following:</Typography>
            <div data-aos="fade-up">
                <Grid container spacing={3} sx={{ marginTop: '5%' }}>
                    <Grid item md={3} sm={6} xs={12}>
                        <CardContent sx={{ backgroundColor: 'white', height: '100%', borderRadius: '20px', boxShadow: 'rgba(235, 235, 235, 0.77) 0px 7px 29px 0px' }}>
                            <CardMedia component='img' image={avail1} sx={{ height: '100px', width: 'auto', marginTop: '-4rem' }} />
                            <h2 style={{ margin: '0', padding: '0' }}>Access to exclusive offers</h2>
                            <p style={{ fontSize: '14px', textAlign:'justify' }} >
                                NFTs can offer exclusive access to virtual/live events, VIP access to platforms/communities.
                            </p>
                        </CardContent>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <CardContent sx={{ backgroundColor: 'white', height: '100%', borderRadius: '20px', boxShadow: 'rgba(235, 235, 235, 0.77) 0px 7px 29px 0px' }}>
                            <CardMedia component='img' image={avail2} sx={{ height: '100px', width: 'auto', marginTop: '-4rem' }} />
                            <h2 style={{ margin: '0', padding: '0' }}>Increased engagement</h2>
                            <p style={{ fontSize: '14px', textAlign:'justify' }} >
                                NFTs encourage engagement with the associated community or platform, through whitelisting and cross-community offers.
                            </p>
                        </CardContent>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <CardContent sx={{ backgroundColor: 'white', height: '100%', borderRadius: '20px', boxShadow: 'rgba(235, 235, 235, 0.77) 0px 7px 29px 0px' }}>
                            <CardMedia component='img' image={avail3} sx={{ height: '100px', width: 'auto', marginTop: '-4rem' }} />
                            <h2 style={{ margin: '0', padding: '0' }}>Access to exclusive content</h2>
                            <p style={{ fontSize: '14px', textAlign:'justify' }} >
                                NFT memberships/newsletters offer exclusive engagement, recognition, and value for holders with famous artists in a community.
                            </p>
                        </CardContent>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <CardContent sx={{ backgroundColor: 'white', height: '100%', borderRadius: '20px', boxShadow: 'rgba(235, 235, 235, 0.77) 0px 7px 29px 0px' }}>
                            <CardMedia component='img' image={avail4} sx={{ height: '100px', width: 'auto', marginTop: '-4rem' }} />
                            <h2 style={{ margin: '0', padding: '0' }}>Increased scarcity and value</h2>
                            <p style={{ fontSize: '14px', textAlign:'justify' }} >
                                NFT offers may appreciate in value over time due to increased demand, making them a valuable investment for holders.
                            </p>
                        </CardContent>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Avail