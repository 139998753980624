import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

let style = {
    '& .Mui-selected': {
        color: '#BC09C7 !important'
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#BC09C7'
    },
    '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root ': {
        textTransform: 'none'
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} sx={style} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Whitelisting" sx={{}} {...a11yProps(0)} />
                    <Tab label="Cross-Communtiy Service Offers" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Typography sx={{ fontSize: { md: '16px', sm: '14px', xs: '12px' }, textAlign:'justify' }}>
                    Whitelisting is a process used in the NFT market to create a list of approved participants
                    who are eligible to participate in a particular NFT sale or event. This is often done to
                    control the number of participants and prevent fraud or market manipulation. Small,
                    up-and-coming communities can target members from large and well-known
                    communities via whitelisting. They can scout members and offer them perks to join their
                    communities.
                </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Typography sx={{ fontSize: { md: '16px', sm: '14px', xs: '12px' }, textAlign:'justify' }}>
                    Every community has numerous services to offer to its members. Apart from
                    speculation, members usually buy NFTs to avail these services. After successful
                    implementation, the community can breakdown their services and target other
                    communities by providing individual service offers on our platform to expand its
                    customer base.
                </Typography>
            </TabPanel>
        </Box>
    );
}