import { Box, Button, CardMedia, Grid, Skeleton, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
// import Carousel from 'react-material-ui-carousel'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Paper } from '@mui/material'
import img0 from '../images/HS.png'
import img1 from '../images/HS1.png'
import img2 from '../images/HS2.png'
import img3 from '../images/HS3.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-scroll'
import { createMuiTheme } from "@material-ui/core"
import { Slide } from 'react-responsive-carousel';

const defaultTheme = createMuiTheme()
const { breakpoints, typography: { pxToRem } } = defaultTheme

const theme = {
    ...defaultTheme,
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: "5rem",
                [breakpoints.down("xs")]: {
                    fontSize: "3rem"
                }
            }
        }
    }
}

const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    border:'none',
    backgroundColor:'#bd09c72d',
    color:'white',
    fontSize:'bold',
    borderRadius:'50px'
};

const indicatorStyles = {
    background: '#fff',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
};

function Herosection() {

    var items = [
        {
            name: "NFTHodlr",
            description: "Incentivising NFT Holding",
            img: img0,
            nav: 'Avail'
        },
        {
            name: "Brands",
            description: "NFT-gated offers platform",
            img: img1,
            nav: 'Brands'
        },
        {
            name: "Communities",
            description: "Whitelisting & Cross-Communtiy Service Offers",
            img: img2,
            nav: 'Communities'
        },
        {
            name: "Artists",
            description: "Artists & NFT Newsletters",
            img: img3,
            nav: 'Artists'
        }
    ]

    return (
        <Box id='Home' sx={{padding:'0% 2%', paddingBottom:'0', margin:'0 2%', marginBottom:'0', marginTop:'2%', borderRadius:'20px', border: { md: '4px solid #BC09C7', xs: '2px solid #BC09C7' }}}>
            <Carousel
                autoPlay
                infiniteLoop
                swipe={true}
                showIndicators={true}
                showThumbs={false}
                cycleNavigation={true}
                interval={2000}
                animationHandler="fade"
                // navButtonsAlwaysVisible={true}
                style={{  borderRadius: '20px' }}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                        &lt;
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        &gt;
                    </button>
                )
            }
            
            >
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        </Box>
    )
}


function Item(props) {
    const [loadImg, setLoadImg] = useState(false)
    return (
        <>
            <Box sx={{ padding: '2%' }} >
                <Grid container>
                    <Grid item md={7} sm={6} xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '5%' }}>
                        <div>
                            <Typography sx={{ color: '#BC09C7', fontFamily: 'Poppins', fontSize: { md: '5rem', sm: '2rem', xs: '1.4rem' }, fontWeight: 'bolder ', margin: '0', padding: '0' }} >{props.item.name}</Typography>
                            <Link to={props.item.nav} spy={true} smooth={true}>
                                <Typography variant='p' sx={{ margin: '0', fontSize: { md: '16px', xs: '12px' }, padding: '0', display: 'flex', alignItems: 'center' }}>{props.item.description} <ArrowForwardIcon sx={{ cursor: 'pointer', display: { md: 'flex', xs: 'none' } }} /></Typography>
                            </Link>
                        </div>
                    </Grid>
                    <Grid item md={5} sm={6} xs={6} sx={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                        <CardMedia component='img'  image={props.item.img} sx={{ height: 'auto', width: { md: '100%', xs: '100%' } }} />
                        
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Herosection