import { Box } from '@mui/material'
import React, { useEffect } from 'react'

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <Box sx={{ padding: '0 3.5%' }}>
            <h3>Privacy Policy</h3>
            <br />
            Owner and Data Controller:
            <br />
            Divytech Blockchain LLP, its subsidiary and related companies
            <br />
            <br />
            Among the types of Personal Data that this Website collects, by itself or through third parties, there are: name, phone number, email address, residence address and social media handles (Twitter, Instagram, etc.)
            <br />
            <br />
            Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Website.
            <br />
            <br />
            Unless specified otherwise, all Data requested by this Website is mandatory and failure to provide this Data may make it impossible for this Website to provide its services. In cases where this Website specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.
            <br />
            <br />
            Any use of Cookies – or of other tracking tools – by this Website or by the owners of third-party services used by this Website serves the purpose of providing the Service required by the User, in addition to marketing purposes and any other purposes described in the present document.
            <br />
            <br />
            Users are responsible for any third-party Personal Data obtained, published or shared through this Website and confirm that they have the third party's consent to provide the Data to the Owner.
            <br />
            <br />

            <strong>Methods of processing</strong>
            <br />
            The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
            <br />
            <br />

            <strong>Place</strong>
            <br />
            The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
            <br />
            Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.
            <br />
            Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.
            <br />
            If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.
            <br />
            Therefore:
            <br />
            Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed. Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner. The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.
            <br />
            <br />

            <strong>Detailed information on the processing of Personal Data</strong>
            <br />
            Personal Data is collected for the following purposes and using the following services:
            <br />
            <br />

            <strong>Contacting the user on sign up and after redeeming the offer</strong>
            <br />

            <strong>Mailing list or newsletter (this Website)</strong>
            <br />
            <br />

            By registering on the mailing list or for the newsletter, the User’s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning this Website. Your email address might also be added to this list as a result of signing up to this Website or after making a purchase.
            <br />
            <br />

            Personal Data processed: name and email address.
            <br />
            <br />
            <br />
            <br />

        </Box>
    )
}
